<template>
	<Dialog :dialog="updateDialog" :dialog-width="1000" @close="$emit('close', true)">
		<template v-slot:title>
			<span>Generate Excel File</span>
		</template>

		<template v-slot:body>
			<v-form
				v-model.trim="formValid"
				ref="excelfile_submit"
				lazy-validation
				v-on:submit.stop.prevent="createOrUpdateExcel"
			>
				<v-row>
					<v-col cols="12">
						<v-row class="mb-2 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Industry Type</label>
							</v-col>
							<!-- :readonly="contract_id > 0 || projectId > 0" -->
							<v-col lg="8" class="my-auto py-0">
								<TextInput
									hide-details
									outlined
									placeholder="Industry Type"
									v-model="excelListing.industry_type"
									class="mt-0"
								>
								</TextInput>
							</v-col>
						</v-row>
						<v-row class="mb-2 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Software Type</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<TextInput
									hide-details
									outlined
									placeholder="Software Type"
									v-model="excelListing.software_type"
									class="mt-0"
								>
								</TextInput>
							</v-col>
						</v-row>
						<v-row class="mb-2 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Excel File</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-file-input
									placeholder="Select File"
									outlined
									class="mt-0"
									prepend-icon=""
									prepend-inner-icon="mdi-attachment"
									hide-details
									v-model="excelListing.excel_file"
								></v-file-input>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-form>
		</template>

		<template v-slot:action>
			<v-btn
				:loading="pageLoading"
				class="white--text mr-2"
				depressed
				color="blue darken-4"
				tile
				:disabled="pageLoading"
				v-on:click="createOrUpdateExcel()"
			>
				Save
			</v-btn>
			<v-btn depressed tile :disabled="pageLoading" @click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import { POST } from "@/core/services/store/request.module";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/core/services/store/common.module";
import MainMixin from "@/core/mixins/main.mixin.js";
export default {
	name: "Create-Excel",
	mixins: [MainMixin],
	props: {
		updateDialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			pageTitle: "Create New Excel File",
			formValid: true,
			pageLoading: false,
			excelListing: {
				industry_type: "",
				software_type: "",
				excel_file: null,
			},
		};
	},
	methods: {
		createOrUpdateExcel() {
			const formErrors = this.validateForm(this.$refs["excelfile_submit"]);
			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}
			let file_extension = "";
			if (this.excelListing.excel_file) {
				const name = this.excelListing.excel_file["name"];
				file_extension = name.split(".").pop();
				const valid_extension = ["xls", "xlsx", "csv"];
				if (!valid_extension.includes(file_extension)) {
					this.$store.commit(SET_ERROR, [{ message: "Invalid File", model: true, timeout: 2000 }]);
					return false;
				}
			} else {
				this.$store.commit(SET_ERROR, [{ message: "Please select File", model: true, timeout: 2000 }]);
				return false;
			}
			this.pageLoading = true;
			const formData = new FormData();
			formData.append("software_type", this.excelListing.software_type);
			formData.append("industry_type", this.excelListing.industry_type);
			formData.append("excel_file", this.excelListing.excel_file);
			formData.append("extension", file_extension);
			this.$store
				.dispatch(POST, {
					url: "generate-pdf",
					data: formData,
				})
				.then(({ data }) => {
					this.$emit("close", true);
					this.$emit("success", data);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		clearFliter() {
			this.date_status = Number(new Date());
			this.filter_reminder_date = null;
			(this.productType = null),
				(this.opportunity = null),
				(this.filter_source = null),
				(this.filter_user = 0),
				(this.defaultFilter = {}),
				this.$nextTick(() => {
					this.getListing();
				});
			this.$router.replace({
				name: "leads",
				query: { t: new Date().getTime() },
				date: [],
			});
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
	components: {
		TextInput,
		Dialog,
	},
};
</script>
